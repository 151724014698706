var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-expos" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c(
          "div",
          { staticClass: "reload", on: { click: _vm.resync } },
          [_c("font-awesome-icon", { attrs: { icon: "sync" } })],
          1
        ),
        _c("h1", [
          _vm._v(_vm._s(_vm.$t("show.title", { name: _vm.currentUser.nom }))),
        ]),
        _c("h2", [_vm._v(_vm._s(_vm.getExpoLabel(_vm.currentExpo)))]),
        _c("h4", [_vm._v(_vm._s(_vm.getExpoDate(_vm.currentExpo)))]),
        _vm.isLoading
          ? _c("div", { staticClass: "loading" }, [
              _vm._v(" " + _vm._s(_vm.$t("global.loading")) + " "),
            ])
          : _vm._e(),
        _vm._l(_vm.getDogsStatusByRace, function (race) {
          return _c(
            "div",
            {
              key: race.id,
              class: `race${race.status === "done" ? " completed" : ""}`,
              attrs: {
                set: (_vm.hours = _vm.getBoundaries(
                  race.todo.concat(race.done)
                )),
              },
            },
            [
              race.status === "done"
                ? _c("span", { staticClass: "valid" })
                : _vm._e(),
              _c(
                "router-link",
                { attrs: { to: { name: "race", params: { id: race.id } } } },
                [
                  _c("button", [
                    _vm._v(_vm._s(_vm.getRaceLabel(_vm.getRaceById(race.id)))),
                  ]),
                ]
              ),
              _c("ul", [
                _c("li", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$tc("show.nb_dogs_to_evaluate", race.todo.length, {
                          count: race.todo.length,
                          total: race.total,
                        })
                      ) +
                      " "
                  ),
                ]),
                _vm.hours.length === 1
                  ? _c("li", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("show.from_hour", { date: _vm.hours })
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _vm.hours.length === 2
                  ? _c("li", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("show.from_to_hour", {
                              from: _vm.hours[0],
                              to: _vm.hours[1],
                            })
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          )
        }),
        _c("div", { staticClass: "totals" }, [
          _vm._v(
            _vm._s(
              _vm.$t("show.total_results", { nb: _vm.getAllResults.length })
            ) +
              " / " +
              _vm._s(_vm.$t("show.nb_dogs", { nb: _vm.getAllDogs.length })) +
              " "
          ),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }